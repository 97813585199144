import React from 'react';
import Fingerprint2 from 'fingerprintjs2';
import axios from 'axios';

import tickLong from './images/tick-long.svg';
import arrowRightLong from './images/arrow-right-long.svg';
import warning from './images/warning.svg';
import helpIcon from './images/help.svg';

import en from './locales/en.json'
import en_IQOS from './locales/en-iqos.json'
import jp from './locales/jp.json'
import de from './locales/de.json'
import fr from './locales/fr.json'
import de_BDR from './locales/de-bdr.json'
import en_BDR from './locales/en-bdr.json'
import es from './locales/es.json'
import hu from './locales/hu.json'



//const LANGUAGE = document.querySelector('body').getAttribute('data-locale') === 'jp-JP' ? jp : document.querySelector('body').getAttribute('data-locale') === 'de-DE' ? de : document.querySelector('body').getAttribute('data-locale') === 'de-BDR' ? de_BDR : document.querySelector('body').getAttribute('data-locale') === 'de-BDR' ? en_BDR : en
let windowLang = document?.documentElement?.lang?.split("-")[0];
let host = window.location.host || window.location.hostname
let LANGUAGE = windowLang === 'fr' ? fr : (windowLang === 'de' ? de : (windowLang === 'es' ? es : (windowLang === 'hu' ? hu : en)))

if ((host === "www.iqosiluma-webinar.hu" || host === "iqosiluma-webinar.hu") && LANGUAGE === 'en') {
  LANGUAGE = en_IQOS;
}

export const BEARER_TOKEN = window.getCookieCheck('token') ? window.getCookieCheck('token') : process.env.REACT_APP_JWT_TOKEN ? process.env.REACT_APP_JWT_TOKEN : '123'

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      criteriaList: [],
      showCase: false,
      showCaseNumber: false,
      caseNumber: ''
    }
  }
  componentDidMount() {
    this.case = ''
    this.caseArray = []
    this.criteria = this.getCriteria(false, false, false, false, false)
  }
  getFingerprint() {
    return new Promise((resolve, reject) => {
      if (window.requestIdleCallback) {
        requestIdleCallback(function () {
          Fingerprint2.get(function (components) {
            resolve(components)
          })
        })
      } else {
        setTimeout(function () {
            Fingerprint2.get(function (components) {
              resolve(components)
            })
        }, 500)
      }
    })
  }
  getCriteria(browser, bandwidth, microphone, camera, audio) {
    return [
        {
            title: LANGUAGE.BROWSER,
            excerpt: LANGUAGE.BROWSER_COPY,
            copy: `${LANGUAGE.BROWSER_COPY_2}

- ${LANGUAGE.BROWSER_CHROME}
- ${LANGUAGE.BROWSER_FIREFOX}
- ${LANGUAGE.BROWSER_SAFARI}
- ${LANGUAGE.BROWSER_EDGE}`,
            status: browser
        },
        {
            title: LANGUAGE.BANDWIDTH,
            excerpt: LANGUAGE.BANDWIDTH_COPY,
            copy: LANGUAGE.BANDWIDTH_COPY_2,
            status: bandwidth
        },
        {
            title: LANGUAGE.MICROPHONE,
            excerpt: LANGUAGE.MICROPHONE_COPY,
            copy: LANGUAGE.MICROPHONE_COPY_2,
            status: microphone
        },
        {
            title: LANGUAGE.CAMERA,
            excerpt: LANGUAGE.CAMERA_COPY,
            copy: `${LANGUAGE.CAMERA_COPY_2}
            
${LANGUAGE.CAMERA_COPY_3}`,
            status: camera
        },
        {
            title: LANGUAGE.AUDIO,
            excerpt: LANGUAGE.AUDIO_COPY,
            copy: LANGUAGE.AUDIO_COPY_2,
            status: audio
        }
    ]
  }
  start() {
    console.log('SystemCheck: initialised.')

    try{
        window.trackEvent('system_check', 'system_check', 'initialised')
    }
        catch(e){
    }

    this.checkBrowser().then((result) => {
        console.log('SystemCheck: checkBrowser result: ' + result.passed + ' : ', result.browser)

        this.caseArray[0] = result.passed ? 'A' : 'C'

        this.checkBandwidth().then((result) => {
            console.log('SystemCheck: checkBandwidth result: ' + result.passed + ' : ', result.value)

            this.caseArray[1] = result.passed ? '1' : '3'

            this.checkMicrophone().then((result) => {
                console.log('SystemCheck: checkMicrophone result: ' + result.passed)

                this.caseArray[2] = result.passed ? 'G' : 'J'

                this.checkCamera().then((result) => {
                    console.log('SystemCheck: checkCamera result: ' + result.passed)

                    this.caseArray[3] = result.passed ? '4' : '6'

                    this.playAudio().then((result) => {
                        console.log('SystemCheck: playAudio result: ' + result.passed)

                        this.caseArray[4] = result.passed ? 'M' : 'P'

                        this.finishTest()
                    })
                })
            })
        })
    })
  }
  finishTest() {
    console.log('SystemCheck: finishTest.', this.caseArray)

    this.case = '';

    this.caseArray.forEach((value) => {
        if (this.case === '') {
            this.case = value;
        } else {
            this.case += '-' + value;
        }
    })

    let criteriaList = [];

    console.log('this.criteria', this.criteria)

    this.criteria = this.getCriteria(this.caseArray[0] === 'A' ? true : false, this.caseArray[1] === '1' ? true : false, this.caseArray[2] === 'G' ? true : false, this.caseArray[3] === '4' ? true : false, this.caseArray[4] === 'M' ? true : false)

    this.criteria.forEach((criteria, index) => {
        criteriaList.push(<li key={index} onClick={() => this.loadView(criteria)} style={{ position: 'relative', display: 'flex', flexDirection: 'column', margin: '0 0 10px', padding: '10px 70px 10px 15px', color: '#343F4C', width: '100%', background: 'white', borderRadius: 2, boxSizing: 'border-box', cursor: 'pointer' }}>
          <strong style={{ fontFamily: '"maneuropebold_condensed", Arial, Helvetica, sans-serif', fontWeight: 'normal' }}>{ criteria.title }</strong>
          <em style={{ fontStyle: 'normal', fontSize: 13 }}>{ criteria.excerpt }</em>
          <span style={{ position: 'absolute', bottom: 10, right: 40, width: 25, height: 25 }}><img alt="Result" src={ criteria.status ? tickLong : warning } style={{ width: '100%', height: '100%' }} /></span>
          <span style={{ position: 'absolute', bottom: 10, right: 10, width: 25, height: 25 }}><img alt="More Information" src={ arrowRightLong } style={{ width: '100%', height: '100%' }} /></span>
        </li>)
    })

    document.cookie = "systemcheck=" + this.case + "; path=/";

    try{
        window.trackEvent('system_check', 'final_check', 'initialised')
    } catch(e) {
      // console.log('error', e)
    }

    this.getFingerprint().then((fingerprint) => {
      console.log('getFingerprint()', fingerprint)

      var form_data = new FormData();
      form_data.append('fingerprint', fingerprint)

      // Show the recaptcha badge for transparency.
      let badges = document.querySelectorAll('.grecaptcha-badge')
      badges.forEach((badge) => {
          badge.style.visibility = 'visible'
      })

      /*eslint-disable */
      grecaptcha.ready(function() {
        grecaptcha.execute(window.GOOGLE_RECAPTCHAV3_SITEKEY, { action: 'submit' }).then(function(captcha) {
          form_data.append(`captcha`, captcha);

          // Hide the recaptcha badge as we are done.
          let badges = document.querySelectorAll('.grecaptcha-badge')
          badges.forEach((badge) => {
              badge.style.visibility = 'hidden'
          })

          axios.post(
            `/wp-json/users/v1/fingerprint?token=${BEARER_TOKEN}`,
            form_data,
            {
              headers: {
                'Authorization': `Bearer ${window.getCookieCheck('token')}`,
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            }
          ).then(response => {
            console.log('fingerprint response 2', response, response.data.case_number)
            if (typeof response.data !== 'undefined' && typeof response.data.case_number !== 'undefined') {
              this.setState({
                showCaseNumber: true,
                caseNumber: response.data.case_number
              })

              try{
                window.trackEvent('system_check', 'final_check', 'success')
              }
              catch(e){
              }
            }
          }).catch(error => {
            console.log('fingerprint catch', error)
            try{
              window.trackEvent('system_check', 'final_check', 'failed', error)
            }
            catch(e){
            }
          })
        })
      })
      /*eslint-enable */
    })

    this.setState({
      criteriaList,
      showCase: true
    })
  }
  loadView(criteria) {
    if (criteria) {
      this.setState({
        view: 'detailed',
        criteria
      })
    } else {
      this.setState({
        view: null,
        criteria: null
      })
    }
  }
  playAudio() {
    return new Promise((resolve, reject) => {
      console.log('SystemCheck: playAudio initialised.')

      try{
        window.trackEvent('system_check', 'audio_check', 'initialised')
      }
      catch(e){
      }

      var audio = new Audio('/platform-content/themes/here-2020/sounds/beep.wav');
      audio.play().then(() => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm(LANGUAGE.DID_YOU_HEAR_THE_AUDIO)) {
          resolve({ passed: true })
        } else {
          resolve({ passed: false })
        }
      }).catch(() => resolve({ passed: false }))
    })
  }
  checkCamera() {
    return new Promise((resolve, reject) => {
      console.log('SystemCheck: checkCamera initialised.')

      try{
          window.trackEvent('system_check', 'camera_check', 'initialised')
      }
      catch(e){
      }

      navigator.mediaDevices.getUserMedia({ video : true }).then((stream) => { stream.getTracks()[0].stop(); resolve({ passed: true }) }).catch((error) => { resolve({ passed: false }) })
    })
  }
  checkMicrophone() {
    return new Promise((resolve, reject) => {
      console.log('SystemCheck: checkMicrophone initialised.')

      try{
        window.trackEvent('system_check', 'microphone_check', 'initialised')
      }
      catch(e){
      }

      navigator.mediaDevices.getUserMedia({ audio : true }).then((result) => { resolve({ passed: true }) }).catch((error) => { resolve({ passed: false }) })
    })
  }
  checkBandwidth() {
    return new Promise((resolve, reject) => {
      console.log('SystemCheck: checkBandwidth initialised.')

      try{
          window.trackEvent('system_check', 'bandwidth_check', 'initialised')
      }
      catch(e){
      }

      var arrTimes = [];
      var i = 0; // start
      var timesToTest = 5;
      var tThreshold = 150; //ms
      var testImage = "https://www.google.com/images/phd/px.gif"; // small image in your server
      var dummyImage = new Image();
      var isConnectedFast = false;

      testLatency(function(avg) {
        isConnectedFast = (avg <= tThreshold);
        resolve({ passed: isConnectedFast ? true : false, value: avg.toFixed(2) })
      });

      /** test and average time took to download image from server, called recursively timesToTest times */
      function testLatency(cb) {
        var tStart = new Date().getTime();
        if (i<timesToTest-1) {
          dummyImage.src = testImage + '?t=' + tStart;
          dummyImage.onload = function() {
            var tEnd = new Date().getTime();
            var tTimeTook = tEnd-tStart;
            arrTimes[i] = tTimeTook;
            testLatency(cb);
            i++;
          };
        } else {
          /** calculate average of array items then callback */
          var sum = arrTimes.reduce(function(a, b) { return a + b; });
          var avg = sum / arrTimes.length;
          cb(avg);
        }
      }
    })
  }
  checkBrowser() {
    return new Promise((resolve, reject) => {
      console.log('SystemCheck: checkBrowser initialised.')

      try{
          window.trackEvent('system_check', 'browser_check', 'initialised')
      }
      catch(e){
      }

      // Opera 8.0+
      // eslint-disable-next-line no-undef
      var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
      // Firefox 1.0+
      var isFirefox = typeof InstallTrigger !== 'undefined';
      // Safari 3.0+ "[object HTMLElementConstructor]"
      // eslint-disable-next-line no-undef
      var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
      // Internet Explorer 6-11
      var isIE = /*@cc_on!@*/false || !!document.documentMode;
      // Edge 20+
      var isEdge = !isIE && !!window.StyleMedia;
      // Chrome 1 - 79
      var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
      // Edge (based on chromium) detection
      var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") !== -1);

      if (isIE) {
        var ua = navigator.userAgent,
            re = new RegExp("MSIE ([0-9]{1,}[\\.0-9]{0,})"),
            rv = null;

        if (re.exec(ua) !== null){
      // eslint-disable-next-line no-undef
          rv = parseFloat( RegExp.$1 );
        }

        resolve(false, 'Internet Explorer ' + rv)

      } else {

        if (isOpera) {
          resolve({ passed: true, browser: 'Opera' })
        } else if (isFirefox) {
          resolve({ passed: true, browser: 'Mozilla Firefox' })
        } else if (isChrome) {
          resolve({ passed: true, browser: 'Google Chrome' })
        } else if (isSafari) {
          resolve({ passed: true, browser: 'Apple Safari' })
        } else if (isEdge) {
          resolve({ passed: true, browser: 'Microsoft Edge' })
        } else if (isEdgeChromium) {
          resolve({ passed: true, browser: 'Microsoft Edge' })
        }

        resolve({ passed: false, browser: 'Undetected' })
      }
    })
  }
  render() {
    return (
      <div style={{ position: 'relative', width: 320, height: '100%', color: 'white' }}>
          <div style={{ display: 'flex', alignItems: 'center', margin: 0, padding: '0 50px 0 15px', borderBottom: '1px solid white', width: '100%', height: 50, boxSizing: 'border-box' }}>
            <strong style={{ fontWeight: 'normal', textTransform: 'uppercase' }}>{ LANGUAGE.HELP }</strong>
          </div>
          <div data-simplebar style={{ overflow: 'scroll' }}>
              {
                this.state.view === 'detailed' ?
                  <div style={{ position: 'relative', margin: '0 0 15px', padding: '15px' }}>
                    <p onClick={() => this.loadView(null)} style={{ margin: '0 0 20px', fontSize: '14px', cursor: 'pointer' }}>
                      &laquo; { LANGUAGE.BACK_TO_OVERVIEW }
                    </p>
                    <span style={{ position: 'relative', display: 'flex', flexDirection: 'column', margin: '0 0 10px', padding: '10px 70px 10px 15px', color: '#343F4C', width: '100%', background: 'white', borderRadius: 2, boxSizing: 'border-box', cursor: 'pointer' }}>
                      <strong style={{ fontWeight: 'normal' }}>{ this.state.criteria.title }</strong>
                      <em style={{ fontStyle: 'normal', fontSize: 13 }}>{ this.state.criteria.excerpt }</em>
                      <span style={{ position: 'absolute', top: 10, right: 10, width: 25, height: 25 }}><img alt="Result" src={ this.state.criteria.status ? tickLong : warning } style={{ width: '100%', height: '100%' }} /></span>
                    </span>
                    <p style={{ paddingLeft: 15, paddingRight: 15, color: 'white', fontSize: 13, lineHeight: '21px', whiteSpace: 'pre-wrap' }}>{ this.state.criteria.copy }</p>
                  </div>
                :
                  <div style={{ position: 'relative', margin: '0 0 5px', padding: '15px' }}>
                    <p style={{ margin: '0 0 20px', fontSize: '14px' }}>
                      { LANGUAGE.CHECK_YOUR_SYSTEM } <span onClick={ () => this.start() } style={{ position: 'absolute', top: 10, right: 15, margin: 0, padding: '5px 0 3px 0', color: 'white', textDecoration: 'none', borderBottom: '1px solid white', cursor: 'pointer' }} data-check-restart>{ LANGUAGE.CHECK }</span>
                    </p>
                    <ul data-check-criteria style={{ margin: 0, padding: 0, listStyle: 'none' }}>
                      { this.state.criteriaList }
                    </ul>
                  </div>
              }
              {
                <>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '15px', width: '100%', borderTop: '1px solid #8F969D', boxSizing: 'border-box' }}>
                    {/* <p style={{ margin: '0 0 20px', fontSize: '14px' }}><strong>{ LANGUAGE.YOUR_CASE_NUMBER_IS } </strong></p> */}
                    {/* <p data-check-case-number style={{ margin: '0 0 20px', padding: '15px 20px', width: '100%', fontFamily: '"maneuropebold_condensed", Arial, Helvetica, sans-serif', fontWeight: 'normal', textAlign: 'center', color: '#343F4C', background: 'white', boxSizing: 'border-box' }}>{ this.state.caseNumber }</p> */}
                    {/* <p style={{ margin: '0 0 20px', fontSize: '14px', fontStyle: 'italic', textAlign: 'center' }}>{ LANGUAGE.FOR_SUPPORT_CONTACT }</p> */}
                    <a href={`mailto:support@xperience.cloud`} target="_blank" style={{ position: 'relative', margin: '0 10px 0 0', padding: '8px 30px', height: 'auto', justifySelf: 'center', alignSelf: 'center', color: 'white', fontSize: 16, fontFamily: '"maneuropebold_condensed", Arial, Helvetica, sans-serif', fontWeight: 'normal', textDecoration: 'none', background: '#48DAD0', borderRadius: 3, border: 'none' }}>{ LANGUAGE.EMAIL_SUPPORT }</a>
                    <span style={{ width: 35, height: 35 }}><img alt="Help" src={ helpIcon } style={{ width: '100%', height: '100%' }} /></span>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0 0 20px', padding: '15px', width: '100%', borderTop: '1px solid #8F969D', boxSizing: 'border-box' }}>
                    <p style={{ margin: '0 0 20px', fontSize: '14px', fontStyle: 'italic', textAlign: 'center', width: '100%' }}>{ LANGUAGE.FOR_SUPPORT_CONTACT }</p>
                  </div>
                </>
              }
          </div>
      </div>
    );
  }
}

export default App;

